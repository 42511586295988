@import "~@ng-select/ng-select/themes/default.theme.css";

ng-select.ng-select,
ng-select.ng-select.ng-select-multiple {
  .ng-select-container {
    @extend.form-control;
    display: flex !important;
    height: auto !important;
    min-height: 0 !important;

    .ng-value-container {
      padding: 0;

      .ng-placeholder {
        padding: 0;
      }

      .ng-input {
        top: auto;
        padding: 0;
      }

      .ng-value {
        margin-bottom: 0px;
      }
    }
  }
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: #e9ecef;
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container {
  background-color: #e9ecef;
}