@media print {
  #section_attachments,
  .ui-toolbar,
  .form-control,
  .btn,
  .builder-bar-wrapper,
  .ehr-nav-tabs,
  .no-print,
  .actionable,
  .history-mode-toggle,
  .builder-container,
  .btn-open-notes,
  .procedure-row,
  .plan-row,
  .medication-row,
  .script-row,
  .type-icon {
    display: none;
  }
  textarea {
    resize: none;
  }
  .show-print {
    display: block;
    display: inline-block;
  }
  .content-wrapper {
    margin: 0 !important;
  }
  .header-wrapper {
    position: relative !important;
    .header-container {
      border: 0 !important;
    }
    .tagline {
      font-weight: bolder;
      font-size: 27px;
      color: black;
    }
    h1 {
      text-transform: uppercase;
    }
  }
  .section-container {
    box-shadow: 0 0 0 0 !important;
    padding: 0 !important;
  }
  
  .history-header {
    border: none !important;
    .col-auto {
      display: none;
    }
    .title {
      font-size: 24px;
      font-weight: bolder !important;
    }
  }
  .history-container {
    border: none !important;
    text-align: left;
  }
  .history-content {
    height: unset !important;
  }
  .history-record {
    justify-content: flex-start;
    .date {
      font-weight: bolder;
    }
    .row {
      gap: 10px;
      justify-content: start;
      flex-direction: row-reverse;
    }
  }
  .diagnosis-section {
    .form-control {
      display: block;
      border: none;
    }
    .ng-value-label {
      span {
        display: none;
      }
    }
    label {
      font-size: 24px;
      font-weight: bolder !important;
    }
  }
  .ng-select {
    .ng-arrow-wrapper {
      display: none;
    }
  }
  .section-item {
    border-bottom: 0 !important;
  }
}
