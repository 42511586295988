.history-container {
  .history-content {
    .history-record {
      p {
        margin-bottom: 0px;
      }
    }
  }
}

.attachment-wrapper {
  margin-top: 5px;
  margin-bottom: 5px;
  .attachment-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 5px;
    padding: 3px 5px;
    border: 1px solid transparent;
    pointer-events: all;

    > mat-icon {
      font-size: 1.4em;
      color: var(--primary);
      &.delete {
        display:none;
        opacity: 0.5;
        margin-left: .5em;
        color: red;
      }
    }
    > mat-icon, > label {
      line-height: 1;
      vertical-align: text-bottom;
      cursor: pointer;
    }

    // border: 1px solid var(--primary);
    // -webkit-box-shadow: 0px 0px 5px -3px rgba(0,0,0,0.5);
    // -moz-box-shadow: 0px 0px 5px -3px rgba(0,0,0,0.5);
    // box-shadow: 0px 0px 5px -3px rgba(0,0,0,0.5);

    &:hover {
      > mat-icon {
        &.delete {
          display:inline-block;
          &:hover {
            opacity: 1;
          }
        }
      }
      cursor: pointer;
      border: 1px solid var(--primary);
      background-color: #eee;
      -webkit-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.5);
      -moz-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.5);
      box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.5);
    }
  }
}
