@import "./vars.scss";

:root {
    --primary: #{$primary};
    --info: #{$info};
    --warning: #{$warning};
    --danger: #{$danger};
    --success: #{$success};
    --background-color: #eee;
    --card-background-color: #fff;
    --dark: #{$dark};
    --mid: #{$mid};
    --light: #{$light};
    --bright: #{$bright};
    --text-color: #{$text};
}

.theme-dark {
    --primary: #{$primary-dark};
    --info: #{$info-dark};
    --warning: #{$warning-dark};
    --danger: #{$danger-dark};
    --success: #{$success-dark};
    --background-color: #111;
    --card-background-color: #222;
    --dark: #{$bright};
    --mid: #{$light};
    --light: #{$mid};
    --bright: #{$dark};
    --text-color: #{$text-dark};
}

html > body {
    color: var(--text-color);

    // custom themeing of specific elements
    &.theme-dark {
        input, textarea, select, input.form-control, textarea.form-control, select.form-control {
            background-color: var(--bright);
            color: var(--text-color);
            &:focus {
                background-color: var(--light);
                color: var(--text-color);
            }
        }
    }
}