/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "assets/styles/card";
@import "assets/styles/forms";
@import "assets/styles/ng-select";

@import "assets/styles/vars";
@import "assets/styles/themes";
@import "assets/styles/globals";

@import "bootstrap/scss/bootstrap";
@import "assets/styles/print";

.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-success,
.btn-outline-danger,
.btn-outline-warning,
.btn-outline-info,
.btn-outline-dark {
  background-color: #fff;
}
.btn-outline-light {
  background-color: #555;
}
body {
  background-color: var(--background-color);
}

.text-jargon {
  color: $primary;
}

.modal-dialog.modal-90 {
  --bs-modal-width: 90%;
}
.show-print {
  display: none;
}
