$primary: #244ea3;
$primary-dark: #2e9ccf;

$info: #2e9ccf;
$info-dark: #244ea3;

    
$success: #28a745;
$success-dark: #28a745;

$warning: #ffc107;
$warning-dark: #ffc107;

$danger: #dc3545;
$danger-dark: #dc3545;

$dark: #444;
$mid: #666;
$light: #ddd;
$bright: #fff;

$dark-dark: #444;
$mid-dark: #666;
$light-dark: #ddd;
$bright-dark: #fff;

$text: #000;
$text-dark: #fff;
