@import "./vars.scss";

mat-form-field {
  width: 100%;
  padding-bottom: 20px;

  &.mat-focused {
    label {
      top: 0 !important;
    }
  }

  &.mat-form-field-should-float {
    label {
      display: none !important;
    }
  }

  .title {
    position: absolute;
    top: -25px;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8f9ba9;
  }

  .mat-form-field-label {
    line-height: 29px;
    padding-left: 16px;
    font-size: 14px;
  }

  .mat-form-field-infix {
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #eceef3;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-input-element {
    font-size: 14px;
    height: 30px;
    padding-left: 16px;
  }

  .mat-select-value {
    font-size: 14px;
    line-height: 29px;
    padding-left: 16px;
  }
}

.mat-form-field-invalid {
  .mat-form-field-infix {
    box-shadow: 0 0 10px red;
  }

  .mat-form-field-label {
    color: #8f9ba9;
  }
}

.invalid {
  .mat-form-field-infix {
    box-shadow: 0 0 10px red;
  }
}

// bootstrap start customization
.form-group {
  position: relative;

  .form-control {
    padding: 0.570rem 1.4rem;
    font-size: 16px;

    &:focus {
      color: #000000;
      outline: 0;
      border-radius: 4px;
      box-shadow: 0 0 4px 0 #244ea2;
      background-color: #ffffff;
      border: solid 1px #244ea2;
    }
  }

  .input-icon {
    position: absolute;
    bottom: 10px;
    right: 0;
  }

  .input-group-addon {
    &.flagInput {
      border-top: 1px solid #ced4da;
      border-left: 1px solid #ced4da;
      border-bottom: 1px solid #ced4da;
    }
  }

  &.has-error {
    label {
      color: #ff4953;
    }

    .ng-invalid {
      border-color: #ff4953;
      box-shadow: 0 0 4px 0 #ff4953;
    }
  }
}

label {
  display: inline-block;
  margin-bottom: 0.2rem;
  font-size: .8em;
  font-weight: 700;
  color: $dark;
}

.custom-select {
  height: 44.22px;
  padding: 0.570rem 1.75rem 0.570rem 1.4rem;
  color: #495057;
}

.custom-checkbox label:hover {
  cursor: pointer;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  background-color: $primary;
  outline: none;
  border: 0;
  box-shadow: none;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: $primary;
  outline: none;
  border: 0;
  box-shadow: none;
}

// bootstrap end customization


.form-control::-webkit-input-placeholder {
  color: #aaa;
  font-size: 14px;
  line-height: 1.43;
}

/* WebKit, Blink, Edge */

.form-control:-moz-placeholder {
  color: #aaa;
  font-size: 14px;
  line-height: 1.43;
}

/* Mozilla Firefox 4 to 18 */

.form-control::-moz-placeholder {
  color: #aaa;
  font-size: 14px;
  line-height: 1.43;
}

/* Mozilla Firefox 19+ */

.form-control:-ms-input-placeholder {
  color: #aaa;
  font-size: 14px;
  line-height: 1.43;
}

/* Internet Explorer 10-11 */

.form-control::-ms-input-placeholder {
  color: #aaa;
  font-size: 14px;
  line-height: 1.43;
}

@-moz-document url-prefix() {
  select.form-control {
    padding-right: 25px;
    background-image: url("data:image/svg+xml,\
      <svg version='1.1' xmlns='http://www.w3.org/2000/svg' width='14px'\
           height='14px' viewBox='0 0 1200 1000' fill='rgb(51,51,51)'>\
        <path d='M1100 411l-198 -199l-353 353l-353 -353l-197 199l551 551z'/>\
      </svg>");
    background-repeat: no-repeat;
    background-position: calc(100% - 7px) 50%;
    -moz-appearance: none;
    appearance: none;
  }
}

.ng-clear-wrapper {
  display: none;
}

.form-intl-group {

  .ng-invalid.ng-dirty,
  .ng-invalid.ng-touched {
    padding: 13px 0;
    border-radius: 4px;
    box-shadow: 0 0 1px 1px #ff4953 !important;
  }
}

.form-group.reverse {
  display: flex;
  flex-direction: column-reverse;

  .ng-invalid.ng-dirty,
  .ng-invalid.ng-touched {
    border-color: #ff4953;
    box-shadow: 0 0 4px 0 #ff4953;

    &~label {
      color: #ff4953;
    }
  }
}


.btn-fill {
  min-width: 10em;
}

.input-group {
  >ng-select {
    &:not(:first-child) {
      >.ng-select-container {
        padding: 0.5rem 0.75rem !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }

    &:not(:last-child) {
      >.ng-select-container {
        padding: 0.5rem 0.75rem !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }
}

// @ref modules/builders/shared/components/status-comment.component.ts
.input-group.status-comment-dynamic-group,
.input-group.field-dynamic-group {
  --status-comment-dynamic-group-gutter: 0.25em;

  &.view-mode-readonly {

    >input,
    textarea,
    button,
    select,
    ng-select,
    .input-group {
      &:nth-last-child(2):not(:first-child) {
        margin-right: var(--status-comment-dynamic-group-gutter) !important;
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
      }

      &:last-child:not(:nth-child(2)) {
        margin-left: var(--status-comment-dynamic-group-gutter) !important;
        border-top-left-radius: 0.25rem !important;
        border-bottom-left-radius: 0.25rem !important;
      }
    }
  }

  >input,
  textarea,
  button,
  select,
  ng-select,
  .input-group {
    &.sc-dynamic-join {
      &.sc-start {
        margin-left: var(--status-comment-dynamic-group-gutter) !important;
        border-top-left-radius: 0.25rem !important;
        border-bottom-left-radius: 0.25rem !important;
      }

      &.sc-end {
        margin-right: var(--status-comment-dynamic-group-gutter) !important;
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
      }
    }

    &:not(.sc-dynamic-join):not(:first-child):not(:last-child) {
      &.input-group {
        >* {
          border-radius: 0;
        }
      }

      &:nth-child(2):not(:nth-last-child(2)) {
        margin-right: var(--status-comment-dynamic-group-gutter) !important;
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;

        &.input-group {
          >:last-child {
            border-top-right-radius: 0.25rem !important;
            border-bottom-right-radius: 0.25rem !important;
          }
        }

        >.ng-select-container {
          padding: 0.4rem 0.75rem !important;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }

      &:nth-last-child(2):not(:nth-child(2)) {
        margin-left: var(--status-comment-dynamic-group-gutter) !important;
        border-top-left-radius: 0.25rem !important;
        border-bottom-left-radius: 0.25rem !important;

        &.input-group {
          >:first-child {
            border-top-left-radius: 0.25rem !important;
            border-bottom-left-radius: 0.25rem !important;
          }
        }

        >.ng-select-container {
          padding: 0.4rem 0.75rem !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
      }

      &:not(:nth-child(2)):not(:nth-last-child(2)) {
        margin-left: var(--status-comment-dynamic-group-gutter) !important;
        margin-right: var(--status-comment-dynamic-group-gutter) !important;
        border-radius: 0.25rem !important;

        &.input-group {
          >:last-child {
            border-top-right-radius: 0.25rem !important;
            border-bottom-right-radius: 0.25rem !important;
          }

          >:first-child {
            border-top-left-radius: 0.25rem !important;
            border-bottom-left-radius: 0.25rem !important;
          }
        }
      }
    }
  }
}

.form-control.ng-invalid.ng-touched {
  border-color: #ff4953;
}


medprax-code-typeahead {
  &.ng-invalid.ng-touched {
    ng-select {
      border-color: #ff4953;
      box-shadow: 0 0 4px 0 #ff4953;
    }
  }
}


.ng-dropdown-panel {
  width: auto !important;
  max-width: 700px;
  min-width: 100%;
}